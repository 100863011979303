import DataHandler from 'o365.modules.DataObject.DataHandler.ts';
import DataObject from 'o365.modules.DataObject.ts';

declare module "o365.modules.DataObject.ts" {
    interface DataObject {
        enableLocalStorageHandler:Function
    }
}

DataObject.prototype.enableLocalStorageHandler = function(){
    this.dataHandler = new LocalStorageHandler(this);
}

class LocalStorageHandler extends DataHandler{
    constructor(pOptions:any){
        super(pOptions);
    }

       async request(pType, pData: any, pHeaders?: any, pOptions?: any){
            if(pType==="retrieve"){
                console.log("It's working");
                 return super.request(pType,pData, pHeaders, pOptions);
            }else{
                return super.request(pType,pData, pHeaders, pOptions);
            }
       }
}